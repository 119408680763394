:root {
  --bg-color: #fff;
  --text-color: #333;
  --text-color2: #777;
  --text-color3: #ccc;
  --border-color: #e1e1e1;
  --border-hover-color: #e0e0e0;
  --box-shadow-color: rgba(0, 0, 0, 0.07);
  --box-shadow-color-hover: rgba(0, 0, 0, 0.15);
  --link-color: #333;
  --link-hover-color: #ff6600;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6;
  padding: 2% 22%;
  background-color: var(--bg-color);
  color: var(--text-color);
}

body.dark {
  --bg-color: #131313;
  --text-color: #ccc;
  --text-color2: #777;
  --text-color3: #333;
  --border-color: #222;
  --border-hover-color: #232323;
  --box-shadow-color: rgba(0, 0, 0, 0.3);
  --box-shadow-color-hover: rgba(0, 0, 0, 0.5);
  --link-color: #fff;
  --link-hover-color: #ff6600;
}

* {
  box-sizing: border-box;
}

@media (max-width: 1400px) {
  body {
    padding: 2% 18%;
  }
  .tab-container {
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  body {
    padding: 2% 5%;
  }
  .tab-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  body {
    padding: 5%;
  }
  .tab-container {
    justify-content: center;
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 50ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 50ms ease-in;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
  font-size: 1.5rem;
  color: #bababa;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  font-size: 1.4em;
  line-height: 0.4em;
  color: var(--text-color);
}

.filter {
  display: none;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.8em;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.parent-container {
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8em;
}

.tab-container {
  display: flex;
  justify-content: left;
}

.tab {
  background-color: var(--bg-color);
  color: var(--text-color2);
  border: none;
  border-bottom: 2px solid transparent;
  padding: 13px 10px 10px 10px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.tab:hover {
  border-bottom: 2px solid var(--link-hover-color);
}

.tab.active {
  background-color: var(--tab-active-bg-color);
  border-bottom: 2px solid var(--link-hover-color);
  color: var(--text-color);
}

.flex-card {
  flex-grow: 1;
}

.new-article-dot {
  height: 10px;
  width: 10px;
  background-color: #ff6600;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.article-row {
  display: flex;
  align-items: flex-start;
}

.card-container {
  padding: 0px;
  align-items: start;
}

.card {
  margin: 0.2em 0;
  padding: 6px 10px 8px 10px;
  border: 1px solid var(--border-color);
  box-shadow: 1px 1px 8px var(--box-shadow-color);
  border-radius: 8px;
}

.card:hover {
  border: 1px solid var(--border-hover-color);
  box-shadow: 1px 1px 8px var(--box-shadow-color-hover);
  transition: box-shadow 1.2s ease-in-out;
}

.card h2 {
  margin: 0;
  font-size: 1em;
  color: var(--text-color);
}

.card h2:hover {
  color: var(--link-hover-color);
  transition: color 0.1s ease-out;
}

.card p {
  margin: 2px 0 0 0;
  font-size: 0.8em;
  color: var(--text-color2);
}

.card p span {
  font-weight: 600;
  color: var(--text-color2);
}

.card a {
  color: var(--text-color);
  text-decoration: none;
  margin-top: 0;
  text-align: left;
}

.card a:visited {
  color: var(--text-color2);
}

.visited {
  opacity: 0.5;
}

.card a:hover {
  color: var(--link-hover-color);
  transition: color 0.1s ease-out;
}

.last-fetch-time {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 11px;
  color: #bababa;
  padding: 5px;
}

.loading-time {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 11px;
  color: #bababa;
  padding: 5px;
}

.switch {
  position: relative;
  margin-left: 1em;
  display: inline-block;
  width: 32px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--border-hover-color);
  -webkit-transition: .3s;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: var(--bg-color);
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  background-color: var(--border-hover-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--border-hover-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.load-more {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.9rem 5rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.1s, box-shadow 2s ease-in-out; 
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius:6px;
  
}

.load-more:hover {
  background-color: var(--bg-color);
  color: var(--link-hover-color);
  border-color: var(--border-hover-color);
  box-shadow: 1px 1px 8px var(--box-shadow-color-hover);
  
}

.article-separator {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, var(--border-color), var(--link-hover-color), var(--border-color));
  margin: 20px 0;
}
